const {
  RecommendationsCardVertical,
  RecommendationsCardHorizontalTitleFirst,
  RecommendationsCardHorizontal,
} = require('@recommendations-fe/card');

module.exports = {
  RecommendationsCardVertical,
  RecommendationsCardHorizontalTitleFirst,
  RecommendationsCardHorizontal,
};
